@charset "UTF-8";
/** -----------------------------------------------------------------------
 *  STRUCTURE
 *  -----------------------------------------------------------------------
 */
html, body {
  min-height: 100%; }

body {
  background-color: #d9d9d9;
  background-image: linear-gradient(160deg, rgba(103, 0, 78, 0), rgba(103, 0, 78, 0.2));
  background-size: 100% 100%;
  background-position: 0 0;
  background-repeat: no-repeat;
  overflow-x: hidden; }

.main {
  min-height: 400px;
  padding-top: 22px;
  padding-bottom: 30px;
  background-color: #FFF; }

a h2, a h3,
a:hover h2,
a:hover h3 {
  text-decoration: none; }

/** -----------------------------------------------------------------------
 *  HEADER
 *  -----------------------------------------------------------------------
 */
.logo-eapn {
  width: 290px;
  height: 120px;
  margin: 0;
  padding: 0;
  background-image: url("../img/logo-eapn-25anos-negativo.svg");
  background-position: center center;
  background-repeat: no-repeat; }

.navbar-default {
  overflow: visible;
  -webkit-box-shadow: 0px -7px 30px #000000;
  box-shadow: 0px -7px 30px #000000;
  background-color: #005E8A;
  background-image: linear-gradient(135deg, #67004e, rgba(103, 0, 78, 0.1));
  background-size: 100% 100%;
  background-position: 0 0;
  background-repeat: no-repeat;
  z-index: 10; }
  .navbar-default .navbar-header {
    margin-right: 15px; }
  .navbar-default .container {
    position: relative;
    z-index: 1; }
  .navbar-default .navbar-nav,
  .navbar-default .navbar-nav > li > a {
    height: 60px;
    line-height: 60px; }
  .navbar-default .navbar-nav-main {
    font-weight: 500;
    text-align: center;
    text-transform: uppercase; }
    .navbar-default .navbar-nav-main > li:hover {
      background-color: rgba(0, 0, 0, 0.05); }
    .navbar-default .navbar-nav-main > li {
      margin-right: 1px;
      background-color: rgba(0, 0, 0, 0.2); }
      .navbar-default .navbar-nav-main > li:first-of-type {
        border-top-left-radius: 15px; }
      .navbar-default .navbar-nav-main > li:last-of-type::before {
        position: absolute;
        left: 100%;
        height: 100%;
        width: 1000px;
        background-color: rgba(0, 0, 0, 0.2);
        content: ''; }
    .navbar-default .navbar-nav-main > li.nav-search-item {
      margin-right: 0; }
      .navbar-default .navbar-nav-main > li.nav-search-item:hover {
        background-color: rgba(0, 0, 0, 0.2); }
  .navbar-default .navbar-nav-secundary,
  .navbar-default .navbar-nav-secundary li a, .navbar-default .navbar-nav-aux,
  .navbar-default .navbar-nav-aux li a {
    color: rgba(255, 255, 255, 0.65); }
  .navbar-default .navbar-nav-secundary .fa, .navbar-default .navbar-nav-aux .fa {
    margin-right: 3px; }
  .navbar-default .navbar-nav-secundary {
    font-size: 14px; }
  .navbar-default .navbar-nav-aux {
    font-size: 13px; }
    .navbar-default .navbar-nav-aux li a {
      padding-left: 10px;
      padding-right: 10px; }

.yamm-fw .dropdown-menu {
  border: none;
  border-radius: 0px;
  -webkit-box-shadow: none;
  box-shadow: none; }
  .yamm-fw .dropdown-menu::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -9999px;
    right: -9999px;
    background-color: #fff;
    -webkit-box-shadow: inset 0 6px 10px rgba(0, 0, 0, 0.175), 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: inset 0 6px 10px rgba(0, 0, 0, 0.175), 0 6px 12px rgba(0, 0, 0, 0.175);
    content: ''; }

.yamm li {
  margin: 0;
  padding: 0;
  line-height: 32px; }

.yamm .list-inline li {
  padding-left: 15px;
  padding-right: 15px; }

.yamm .yamm-content {
  position: relative;
  padding: 0 15px; }
  .yamm .yamm-content a,
  .yamm .yamm-content a:active,
  .yamm .yamm-content a:visited {
    padding-bottom: 2px;
    color: #005E8A; }
  .yamm .yamm-content a:hover,
  .yamm .yamm-content a:focus {
    color: #67004E;
    text-decoration: none;
    border-bottom: 2px solid; }

.owl-banner {
  margin-top: 15px;
  margin-bottom: 20px; }

@media only screen and (min-width: 768px) {
  /* Make Navigation Toggle on Desktop Hover */
  .dropdown:hover
.dropdown-menu {
    display: block; } }

/** -----------------------------------------------------------------------
 *  SEARCH
 *  -----------------------------------------------------------------------
 */
.navbar-with-search .search-bar {
  display: block;
  position: absolute;
  top: 100%;
  left: -9999px;
  right: -9999px;
  border: none;
  background-color: #fff;
  -webkit-box-shadow: inset 0 6px 10px rgba(0, 0, 0, 0.175), 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: inset 0 6px 10px rgba(0, 0, 0, 0.175), 0 6px 12px rgba(0, 0, 0, 0.175);
  visibility: hidden; }
  .navbar-with-search .search-bar.open {
    visibility: visible; }

.navbar-with-search .form-control {
  height: 52px;
  padding: 0;
  font-size: 18px;
  border-radius: 0px;
  border-width: 0px;
  background-color: transparent; }
  .navbar-with-search .form-control:focus {
    border-color: transparent;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none; }

.navbar-with-search .btn-default {
  padding: 14px 16px 14px 15px;
  color: rgba(103, 0, 78, 0.2);
  background: transparent;
  border: none;
  outline: 0; }
  .navbar-with-search .btn-default:active, .navbar-with-search .btn-default:visited, .navbar-with-search .btn-default:hover, .navbar-with-search .btn-default:focus {
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0; }

.content .gsc-control-cse {
  padding: 0; }
  .content .gsc-control-cse .gsc-results {
    width: 100%; }
  .content .gsc-control-cse .gsc-result-info {
    padding-left: 0; }
  .content .gsc-control-cse .gsc-webResult.gsc-result {
    margin-top: 15px;
    margin-bottom: 15px;
    padding-top: 0;
    padding-bottom: 15px;
    border: 0;
    border-bottom: 1px solid #d9d9d9; }
  .content .gsc-control-cse .gsc-table-result,
  .content .gsc-control-cse .gsc-thumbnail-inside,
  .content .gsc-control-cse .gsc-url-top {
    padding-left: 0px; }
  .content .gsc-control-cse .gs-result .gs-title,
  .content .gsc-control-cse .gs-result .gs-title * {
    height: auto;
    margin: 0;
    font-weight: 500;
    text-transform: none;
    text-decoration: none;
    color: #67004E; }
    .content .gsc-control-cse .gs-result .gs-title b, .content .gsc-control-cse .gs-result .gs-title a, .content .gsc-control-cse .gs-result .gs-title a:visited, .content .gsc-control-cse .gs-result .gs-title a:active, .content .gsc-control-cse .gs-result .gs-title a:hover, .content .gsc-control-cse .gs-result .gs-title a:focus,
    .content .gsc-control-cse .gs-result .gs-title * b,
    .content .gsc-control-cse .gs-result .gs-title * a,
    .content .gsc-control-cse .gs-result .gs-title * a:visited,
    .content .gsc-control-cse .gs-result .gs-title * a:active,
    .content .gsc-control-cse .gs-result .gs-title * a:hover,
    .content .gsc-control-cse .gs-result .gs-title * a:focus {
      text-decoration: underline;
      color: #67004E; }
    .content .gsc-control-cse .gs-result .gs-title a:hover, .content .gsc-control-cse .gs-result .gs-title a:focus,
    .content .gsc-control-cse .gs-result .gs-title * a:hover,
    .content .gsc-control-cse .gs-result .gs-title * a:focus {
      text-decoration: underline;
      color: #67004E; }
    .content .gsc-control-cse .gs-result .gs-title b,
    .content .gsc-control-cse .gs-result .gs-title * b {
      font-weight: 700; }
  .content .gsc-control-cse .gs-fileFormat,
  .content .gsc-control-cse .gs-result a.gs-visibleUrl,
  .content .gsc-control-cse .gs-result .gs-visibleUrl {
    margin: 0;
    font-family: "Roboto", sans-serif;
    font-size: 13px;
    color: #999999;
    text-decoration: none; }
  .content .gsc-control-cse .gs-snippet {
    font-family: "Droid Serif", Georgia, "Times New Roman", Times, serif;
    line-height: 1.466666667;
    color: #000;
    background-color: #FFF; }
  .content .gsc-control-cse .gsc-cursor-box {
    display: inline-block;
    padding-left: 0;
    margin: 22px 0;
    font-family: "Droid Serif", Georgia, "Times New Roman", Times, serif;
    font-size: 15px;
    border-radius: 3px; }
    .content .gsc-control-cse .gsc-cursor-box .gsc-cursor {
      border-radius: 3px; }
    .content .gsc-control-cse .gsc-cursor-box .gsc-cursor-page {
      margin: 0 0 0 -1px;
      padding: 6px 12px;
      line-height: 1.466666667;
      text-decoration: none;
      color: #67004E;
      background-color: #fff;
      border: 1px solid #ddd;
      outline: 0; }
      .content .gsc-control-cse .gsc-cursor-box .gsc-cursor-page:first-child {
        margin-left: 0;
        border-bottom-left-radius: 3px;
        border-top-left-radius: 3px; }
      .content .gsc-control-cse .gsc-cursor-box .gsc-cursor-page:last-child {
        border-bottom-right-radius: 3px;
        border-top-right-radius: 3px; }
      .content .gsc-control-cse .gsc-cursor-box .gsc-cursor-page:hover, .content .gsc-control-cse .gsc-cursor-box .gsc-cursor-page:focus {
        color: #1b0014;
        background-color: #d9d9d9;
        border-color: #ddd;
        text-decoration: none; }
    .content .gsc-control-cse .gsc-cursor-box .gsc-cursor-current-page,
    .content .gsc-control-cse .gsc-cursor-box .gsc-cursor-current-page:hover,
    .content .gsc-control-cse .gsc-cursor-box .gsc-cursor-current-page:focus {
      z-index: 3;
      color: #fff;
      background-color: #67004E;
      border-color: #67004E;
      cursor: default; }

/** -----------------------------------------------------------------------
 *  ORDERED LISTS
 *  -----------------------------------------------------------------------
 */
ol {
  counter-reset: li; }
  ol > li {
    position: relative;
    list-style: none; }
    ol > li::before {
      content: counter(li);
      /* Use the counter as content */
      counter-increment: li;
      /* Increment the counter by 1 */
      position: absolute;
      width: 1.9em;
      top: 0;
      left: -35px;
      padding: 0 0 0 5px;
      font-family: "Roboto", sans-serif;
      font-weight: 600;
      text-transform: uppercase;
      background-color: rgba(103, 0, 78, 0.1); }

/** -----------------------------------------------------------------------
 *  ASIDE
 *  -----------------------------------------------------------------------
 */
.aside .nav {
  margin-top: 18px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 600; }
  .aside .nav > li > a {
    padding-left: 0;
    padding-right: 0;
    color: #005E8A;
    text-transform: uppercase; }
    .aside .nav > li > a:hover, .aside .nav > li > a:focus {
      font-weight: 700;
      background: none;
      color: #00344d; }
  .aside .nav ul {
    padding-left: 15px;
    list-style: none; }
    .aside .nav ul > li {
      position: relative; }
    .aside .nav ul > li::before {
      position: absolute;
      left: -15px;
      font-weight: 600;
      color: rgba(103, 0, 78, 0.25);
      content: "\2014"; }
    .aside .nav ul a:hover,
    .aside .nav ul a:focus,
    .aside .nav ul a:active {
      text-decoration: none; }

/** -----------------------------------------------------------------------
 *  CONTENT
 *  -----------------------------------------------------------------------
 */
.page-title {
  margin-bottom: 22px;
  padding-bottom: 8px;
  color: #67004E;
  font-size: 24px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: 5px solid rgba(103, 0, 78, 0.1); }
  .page-title small::before {
    content: ' / '; }

.page-pretitle {
  margin-top: 22px;
  margin-bottom: 2px;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  text-transform: uppercase;
  color: #999999; }
  .page-pretitle + .page-title {
    margin-top: 0; }

.page-subtitle {
  min-height: 28px; }
  .page-subtitle .item-label {
    margin-left: 15px; }
    .page-subtitle .item-label:first-child {
      margin-left: 0; }
  .page-subtitle .addthis_sharing_toolbox {
    position: relative;
    float: right;
    top: -1px;
    margin-left: 5px; }
    .page-subtitle .addthis_sharing_toolbox .at-icon-wrapper {
      border-radius: 3px; }

.area-title {
  padding-bottom: 8px;
  color: #67004E;
  font-size: 24px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: 5px solid rgba(103, 0, 78, 0.1); }

.item-label {
  font-family: "Roboto", sans-serif;
  color: #999999; }

.inline-highlight {
  padding: 0 5px;
  margin-right: 4px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  background-color: rgba(103, 0, 78, 0.1); }

.list-items a,
.list-items .date {
  display: block; }

.files-list {
  margin-top: 30px;
  font-family: "Roboto", sans-serif; }
  .files-list .fa {
    padding: 10px;
    margin-right: 6px;
    background-color: #d9d9d9;
    border-radius: 50%; }
  .files-list .item-files-list {
    margin-bottom: 15px; }
  .files-list a {
    color: #005E8A; }
    .files-list a .fa {
      color: #000; }
    .files-list a:hover {
      text-decoration: none; }
      .files-list a:hover .fa {
        color: #FFF;
        background-color: #67004E; }

.row-actions {
  margin-top: 30px;
  margin-bottom: 30px; }

.item-equipa {
  margin-top: 10px;
  margin-bottom: 5px; }
  .item-equipa .photo {
    display: block;
    width: 100px;
    margin: 0 0 5px 0; }

/** -----------------------------------------------------------------------
 *  HIGHLIGHTS
 *  -----------------------------------------------------------------------
 */
.highlights {
  position: relative;
  height: 205px;
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: "Roboto", sans-serif; }
  .highlights-link {
    position: relative;
    display: block;
    width: 100%;
    height: 100%; }
    .highlights-link::after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(45deg, rgba(0, 94, 138, 0) 0%, rgba(0, 94, 138, 0) 50%, rgba(0, 94, 138, 0.2) 100%) repeat scroll 0 0%, transparent linear-gradient(transparent 30%, rgba(0, 0, 0, 0.5) 100%) repeat scroll 0 0;
      content: '';
      z-index: 0; }
  .highlights-label {
    position: absolute;
    bottom: 0px;
    margin: 0;
    padding: 15px;
    font-size: 13px;
    line-height: 1.35;
    color: #FFF;
    z-index: 1; }
    .highlights-label::after {
      position: absolute;
      top: 0;
      left: 15px;
      width: 50px;
      height: 1px;
      background-color: #FFF;
      content: ''; }
  .highlights-nav-button {
    position: absolute;
    top: 78px;
    color: rgba(103, 0, 78, 0.2); }
    .highlights-nav-button.previous {
      left: -15px; }
    .highlights-nav-button.next {
      right: -15px; }
    .highlights-nav-button:hover {
      color: #67004E; }

/** -----------------------------------------------------------------------
 *  LIST
 *  -----------------------------------------------------------------------
 */
.content-list-item {
  margin-top: 15px;
  margin-bottom: 15px; }

.content-list-image {
  border: 1px solid #f2f2f2; }

.content-list-date, .content-list-type {
  margin: 0 0 2px 0;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  color: #999999; }

.content-list-title {
  display: block;
  margin: 0 0 5px 0;
  font-weight: 500;
  text-transform: none;
  color: #67004E; }
  .content-list-title a:hover {
    text-decoration: none;
    color: #67004E; }

.content-list-details {
  margin: 0; }

.content-list.bordered > li {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #d9d9d9; }
  .content-list.bordered > li > a {
    display: block; }
    .content-list.bordered > li > a:hover, .content-list.bordered > li > a:focus {
      text-decoration: none; }

/** -----------------------------------------------------------------------
 *  FULLSCREEN
 *  -----------------------------------------------------------------------
 */
.fullscreen {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  color: #FFF;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #F5F5F5;
  z-index: 0; }

/** -----------------------------------------------------------------------
 *  DETALHES DE CONTEUDO
 *  -----------------------------------------------------------------------
 */
.agenda .page-subtitle,
.eventos .page-subtitle,
.documento .page-subtitle,
.projeto .page-subtitle,
.campanha .page-subtitle {
  margin-bottom: 15px; }

.formacao .page-subtitle {
  margin-bottom: 7px; }

.centro-de-documentacao .publication-title {
  display: block;
  margin-top: 15px;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 13px; }

.publication-image {
  float: left;
  margin-right: 30px;
  margin-bottom: 15px;
  border: 1px solid #CCC; }

.nucleos #map {
  width: 100%;
  height: 200px;
  margin-top: 30px;
  margin-bottom: 30px; }

/** -----------------------------------------------------------------------
 *  CONHEÇA MELHOR
 *  -----------------------------------------------------------------------
 */
.conheca-melhor {
  margin-bottom: 30px; }
  .conheca-melhor a {
    position: relative;
    display: block;
    margin-bottom: 5px;
    padding: 10px 5px;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    color: #67004E;
    background-color: rgba(0, 94, 138, 0.2);
    background-image: linear-gradient(135deg, rgba(103, 0, 78, 0.4), rgba(103, 0, 78, 0));
    background-size: 100% 100%;
    background-position: 0 0;
    background-repeat: no-repeat; }
    .conheca-melhor a:hover, .conheca-melhor a:active {
      color: #FFF;
      text-decoration: none;
      background-color: #005E8A;
      background-image: linear-gradient(135deg, #67004e, rgba(103, 0, 78, 0.15));
      background-size: 100% 100%;
      background-position: 0 0;
      background-repeat: no-repeat; }
    .conheca-melhor a .fa {
      margin-right: 5px;
      line-height: 26px; }

/** -----------------------------------------------------------------------
 *  ASSOCIADOS
 *  -----------------------------------------------------------------------
 */
.associate-users label {
  font-size: 13px;
  color: #333333;
  margin-left: 15px; }

.associate-users input {
  display: inline;
  width: 250px;
  margin-left: 10px; }

/** -----------------------------------------------------------------------
 *  CONTACTS
 *  -----------------------------------------------------------------------
 */
.contactos .main {
  padding-top: 0; }

.section-google-map {
  height: 300px;
  margin-bottom: 30px;
  -webkit-box-shadow: 0px -7px 30px #000000;
  box-shadow: 0px -7px 30px #000000; }

/** -----------------------------------------------------------------------
 *  FOOTER
 *  -----------------------------------------------------------------------
 */
.footer {
  padding-bottom: 15px;
  font-family: "Roboto", sans-serif;
  -webkit-box-shadow: inset 0 20px 20px -20px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0 20px 20px -20px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 20px 20px -20px rgba(0, 0, 0, 0.2); }
  .footer,
  .footer a {
    color: #333333;
    text-decoration: none; }
  .footer-title {
    font-weight: 700; }
  .footer-bar {
    margin-bottom: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-family: "Roboto", sans-serif;
    background-color: rgba(0, 0, 0, 0.07); }
    .footer-bar,
    .footer-bar a {
      color: #67004E; }
  .footer-content {
    font-size: 14px; }
  .footer-content-title, .footer-link-title {
    display: block;
    font-weight: 500;
    color: #67004E; }
  .footer-link {
    white-space: nowrap; }
    .footer-link::after {
      position: relative;
      top: -1px;
      font-size: 11px;
      content: '\00a0\00a0/\00a0\00a0'; }
  .footer-link-social {
    margin-right: 15px; }
    .footer-link-social .fa {
      margin-right: 5px; }
  .footer .back-to-top {
    margin-top: 11px;
    margin-bottom: 11px;
    font-size: 13px;
    text-transform: uppercase; }
  .footer hr {
    border-top-color: #999999; }
  .footer .bizview {
    display: block;
    position: relative;
    width: 80px;
    height: 20px;
    top: -3px;
    background-image: url("../img/bizview.svg");
    background-position: center center;
    background-repeat: no-repeat; }

/** -----------------------------------------------------------------------
 *  FORMS
 *  -----------------------------------------------------------------------
 */
.form-control {
  outline: none; }
  .form-control:focus {
    box-shadow: 0 0 0; }

/** -----------------------------------------------------------------------
 *  MODALS
 *  -----------------------------------------------------------------------
 */
.modal-mailchimp .mc-field-group {
  margin-bottom: 10px; }

.modal-mailchimp .response {
  font-family: "Roboto", sans-serif;
  margin-top: 15px;
  margin-bottom: 15px; }

.modal-mailchimp div.mce_inline_error {
  margin-top: 5px;
  font-size: 13px;
  color: #67004E; }

.modal-mailchimp small {
  display: block; }
  .modal-mailchimp small a {
    text-decoration: underline; }

/** -----------------------------------------------------------------------
 *  HELPER CLASSES
 *  -----------------------------------------------------------------------
 */
.centered-table {
  position: relative;
  display: table;
  height: 100%;
  width: 100%; }
  .centered-table > .content-table {
    display: table-cell;
    text-align: center;
    vertical-align: middle; }

.row-height {
  display: table;
  table-layout: fixed;
  height: 100%;
  width: 100%; }

.col-height {
  display: table-cell;
  float: none;
  height: 100%; }

.block {
  display: block !important; }

.inline-block {
  display: inline-block !important; }

.align-top {
  vertical-align: top; }

.align-middle {
  vertical-align: middle; }

.align-bottom {
  vertical-align: bottom; }

.semi-bold {
  font-weight: 600 !important; }

.bold {
  font-weight: 700 !important; }

.font-small {
  font-size: 13px !important; }

.no-padding {
  padding: 0 !important; }

.no-padding-left {
  padding-left: 0 !important; }

.no-padding-right {
  padding-right: 0 !important; }

.no-margin {
  margin: 0 !important; }

.p-margin {
  margin: 0 0 11px; }

.margin-left {
  margin-left: 15px !important; }

.margin-right {
  margin-right: 15px !important; }

.no-glow {
  outline: none;
  box-shadow: none !important; }

.no-border {
  border: none !important; }

.color-blue {
  color: #005E8A !important; }
