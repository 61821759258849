// Variables
@import "bootstrap-sass/eapn/variables";
@import "../bower/bootstrap-sass/assets/stylesheets/bootstrap/variables";

// Mixins
@import "../bower/bootstrap-sass/assets/stylesheets/bootstrap/mixins";
@import "bootstrap-sass/eapn/mixins/custom";


/** -----------------------------------------------------------------------
 *  STRUCTURE
 *  -----------------------------------------------------------------------
 */

html, body {
    min-height: 100%;
}

body {
    @include gradient($gray-lighter, 160, $purple, 0, .20);
    overflow-x: hidden;
}

.main {
    min-height: 400px;
    padding-top: $line-height-computed;    
    padding-bottom: $grid-gutter-width;
    background-color: #FFF;
}

a,
a:hover {
    h2, h3 {
        text-decoration: none;
    }
}


/** -----------------------------------------------------------------------
 *  HEADER
 *  -----------------------------------------------------------------------
 */

.logo-eapn {
    width: $logo-width + 30px;
    height: $navbar-height;
    margin: 0;
    padding: 0;
    // background-color: $blue;
    background-image: url('../img/logo-eapn-25anos-negativo.svg');
    background-position: center center;
    background-repeat: no-repeat;
}

.navbar-default {

    // border-bottom: 5px solid $purple;
    overflow: visible;
    @include box-shadow(0px -7px 30px #000000)
    // @include gradient($blue, 135, $purple, 1, .15);
    @include gradient($blue, 135, $purple, 1, .10);
    z-index: 10;

    .navbar-header {
        margin-right: ($grid-gutter-width / 2);
    }

    .container {
        position: relative;
        z-index: 1;
    }

    .navbar-nav {

        &,
        & > li > a {
            height: ($navbar-height / 2);
            line-height: ($navbar-height / 2);
        }


        &-main {
            // position: relative;
            // font-size: 14px;
            font-weight: 500;
            text-align: center;
            text-transform: uppercase;

            & > li:hover {
                background-color: rgba(0, 0, 0, .05);
            }

            & > li {
                margin-right: 1px;
                background-color: rgba(0, 0, 0, .2);

                &:first-of-type {
                    border-top-left-radius: 15px;
                }

                &:last-of-type::before {
                    position: absolute;
                    left: 100%;
                    height: 100%;
                    width: 1000px;
                    // margin-left: 1px;
                    background-color: rgba(0, 0, 0, .2);
                    content: '';
                }
            }

            & > li.nav-search-item {
                margin-right: 0;

                &:hover {
                    background-color: rgba(0, 0, 0, .2);
                }
            }

            // & > li +li {
            //     border-left: none;
            // }
        }

        &-secundary,
        &-aux {
            &, 
            & li a {
                color: rgba(255, 255, 255, 0.65);
            }

            .fa {
                margin-right: 3px;
            }
        }

        &-secundary {
            font-size: 14px;
        }

        &-aux {
            font-size: 13px;

            & li a {
                padding-left: 10px;
                padding-right: 10px;
            }
        }

    }

    // &::after {
    //     position: absolute;
    //     left: 0;
    //     top: 0;
    //     width: 100%;
    //     height: 100%;
    //     // @include gradient($blue, 135, $purple, 1, .15);
    //     background: red;
    //     @include box-shadow(0 6px 12px rgba(0,0,0,.175));
    //     content: '';
    // }

}

.yamm-fw .dropdown-menu {

    border: none;
    border-radius: 0px;
    @include box-shadow(none);

    &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: -9999px;
        right: -9999px;
        background-color: $dropdown-bg;
        @include box-shadow(inset 0 6px 10px rgba(0,0,0,.175), 0 6px 12px rgba(0,0,0,.175));
        content: '';
    }
}

.yamm {

    li {
        margin: 0;
        padding: 0;
        line-height: $line-height-computed + 10px;
    }

    .list-inline li {
        padding-left: ($grid-gutter-width / 2);
        padding-right: ($grid-gutter-width / 2);
    }

    .yamm-content {
        position: relative;
        padding: 0 ($grid-gutter-width / 2);

        a,
        a:active,
        a:visited {
            padding-bottom: 2px;
            color: $blue;
        }

        a:hover,
        a:focus {
            color: $purple;
            text-decoration: none;
            border-bottom: 2px solid;
        }
    }
}

.owl-banner {
    margin-top: ($grid-gutter-width / 2);
    margin-bottom: 20px;
}

@media only screen and (min-width : 768px) {
    /* Make Navigation Toggle on Desktop Hover */
    .dropdown:hover 
    .dropdown-menu {
        display: block;
    }
}


/** -----------------------------------------------------------------------
 *  SEARCH
 *  -----------------------------------------------------------------------
 */

.navbar-with-search {

    .search-bar {
        display: block;
        position: absolute;
        top: 100%;
        left: -9999px;
        right: -9999px;
        border: none;
        background-color: $dropdown-bg;
        @include box-shadow(inset 0 6px 10px rgba(0,0,0,.175), 0 6px 12px rgba(0,0,0,.175));
        visibility: hidden;

        &.open {
            visibility: visible;    
        }
    }

    .form-control {
        height: 52px;
        padding: 0;
        font-size: $font-size-large;
        border-radius: 0px;
        border-width: 0px;
        background-color: transparent;

        &:focus {
            border-color: transparent;
            outline: 0;
            -webkit-box-shadow: none;
            box-shadow: none;
        }
    }

    .btn-default {
        padding: 14px 16px 14px 15px;
        color: transparentize($purple, .8);
        background: transparent;
        border: none;
        outline: 0;

        &:active,
        &:visited,
        &:hover,
        &:focus {
            background: transparent;
            @include box-shadow(none);
            outline: 0;
        }
    }
}

// .gcsc-branding {
//     display: none;
// }

.content .gsc-control-cse {
    padding: 0;

    .gsc-results {
        width: 100%;
    }

    .gsc-result-info {
        padding-left: 0;
    }

    .gsc-webResult.gsc-result {
        margin-top:    ($grid-gutter-width / 2);
        margin-bottom: ($grid-gutter-width / 2);
        padding-top:    0;
        padding-bottom: ($grid-gutter-width / 2);
        border: 0;
        border-bottom: 1px solid $hr-border;
    }

    .gsc-table-result, 
    .gsc-thumbnail-inside, 
    .gsc-url-top {
        padding-left: 0px;
    }

    .gs-result .gs-title, 
    .gs-result .gs-title * {
        height: auto;
        margin: 0;
        font-weight: 500;
        text-transform: none;
        text-decoration: none;
        color: $purple;

        b, a, a:visited, a:active, a:hover, a:focus {
            text-decoration: underline;
            color: $purple;
        }

        a:hover, a:focus {
            text-decoration: underline;
            color: $purple;
        }

        b {
            font-weight: 700;
        }
    }

    .gs-fileFormat,
    .gs-result a.gs-visibleUrl, 
    .gs-result .gs-visibleUrl {
        margin: 0;
        font-family: $font-family-sans-serif;
        font-size: $font-size-small;
        color: $gray; 
        text-decoration: none;
    }

    .gs-snippet {
        font-family: $font-family-base;
        line-height: $line-height-base;
        color: $text-color;
        background-color: $body-bg;
    }

    // Paginator
    .gsc-cursor-box {
        display: inline-block;
        padding-left: 0;
        margin: $line-height-computed 0;
        font-family: $font-family-base;
        font-size: $font-size-base;
        border-radius: $border-radius-base;

        .gsc-cursor {
            border-radius: $border-radius-base;
        }

        .gsc-cursor-page {
            margin: 0 0 0 -1px;
            padding: $padding-base-vertical $padding-base-horizontal;
            line-height: $line-height-base;
            text-decoration: none;
            color: $pagination-color;
            background-color: $pagination-bg;
            border: 1px solid $pagination-border;
            outline: 0;

            &:first-child {
                margin-left: 0;
                @include border-left-radius($border-radius-base);
            }

            &:last-child {
                @include border-right-radius($border-radius-base);
            }

            &:hover,
            &:focus {
                color: $pagination-hover-color;
                background-color: $pagination-hover-bg;
                border-color: $pagination-hover-border;
                text-decoration: none;
            }
        }

        .gsc-cursor-current-page,
        .gsc-cursor-current-page:hover,
        .gsc-cursor-current-page:focus {
            z-index: 3;
            color: $pagination-active-color;
            background-color: $pagination-active-bg;
            border-color: $pagination-active-border;
            cursor: default;
        }

    }

}


/** -----------------------------------------------------------------------
 *  ORDERED LISTS
 *  -----------------------------------------------------------------------
 */
ol {
    counter-reset: li;

    > li {
        position:relative;
        list-style:none;

        &::before {
            content:counter(li); /* Use the counter as content */
            counter-increment:li; /* Increment the counter by 1 */
            position:absolute;
            width: 1.9em;
            top: 0;
            left: -($grid-gutter-width + 5px);
            padding: 0 0 0 5px;
            font-family: $font-family-sans-serif;
            font-weight: 600;
            text-transform: uppercase;
            background-color: transparentize($purple, .9);
        }
    }
}


/** -----------------------------------------------------------------------
 *  ASIDE
 *  -----------------------------------------------------------------------
 */

.aside {
    .nav {
        margin-top: 18px;
        font-family: $font-family-sans-serif-condensed;
        font-weight: 600;

        > li > a {
            padding-left: 0;
            padding-right: 0;
            color: $blue;
            text-transform: uppercase;  

            &:hover,
            &:focus {
                font-weight: 700;
                background: none;
                color: $blue-dark;
            }
        }

        ul {
            padding-left: ($grid-gutter-width / 2);
            list-style: none;

            > li {
                position: relative
            }

            > li::before {
                position: absolute;
                left: -($grid-gutter-width / 2);
                font-weight: 600;
                color: transparentize($purple, .75);
                content: "\2014";
            }

            a:hover,
            a:focus,
            a:active {
                text-decoration: none;
            }
        }
    }
}


/** -----------------------------------------------------------------------
 *  CONTENT
 *  -----------------------------------------------------------------------
 */

.page-title {
    margin-bottom: $line-height-computed;
    padding-bottom: (ceil($grid-gutter-width / 4));
    color: $purple;
    font-size: $font-size-h2;
    font-family: $font-family-sans-serif-condensed;
    font-weight: 700;
    text-transform: uppercase;
    border-bottom: 5px solid transparentize($purple, .9);

    small::before {
        content: ' / ';
    }
}

.page-pretitle {
    margin-top: $line-height-computed;
    margin-bottom: 2px;
    font-family: $font-family-sans-serif;
    font-size: $font-size-small;
    text-transform: uppercase;
    color: $gray;

    + .page-title {
        margin-top: 0;
    }
}

.page-subtitle {

    min-height: 28px;

    .item-label {
        margin-left: ($grid-gutter-width / 2);

        &:first-child {
            margin-left: 0;
        }
    }

    .addthis_sharing_toolbox {
        position: relative;
        float: right;
        top: -1px;
        margin-left: 5px;

        .at-icon-wrapper {
            border-radius: 3px;
        }
    }

}

.area-title {
    padding-bottom: (ceil($grid-gutter-width / 4));
    color: $purple;
    font-size: $font-size-h2;
    font-family: $font-family-sans-serif-condensed;
    font-weight: 700;
    text-transform: uppercase;
    border-bottom: 5px solid transparentize($purple, .9);
}

.item-label {
    font-family: $font-family-sans-serif;
    color: $gray;
}

.inline-highlight {
    padding: 0 5px;
    margin-right: 4px;
    font-family: $font-family-sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    background-color: transparentize($purple, .9);
}

.list-items {
    a,
    .date {
        display: block;
    }
}

.files-list {

    margin-top: $grid-gutter-width;
    font-family: $font-family-sans-serif;

    .fa {
        padding: 10px;
        margin-right: 6px;
        background-color: $gray-lighter;
        border-radius: 50%;
    }

    .item-files-list {
        margin-bottom: ($grid-gutter-width / 2);
    }

    a {
        color: $blue;

        .fa {
            color: $text-color;
        }


        &:hover {
            text-decoration: none;
    
            .fa {
                color: #FFF;
                background-color: $purple;
            }
        }
    }
}

.row-actions {
    margin-top: $grid-gutter-width;
    margin-bottom: $grid-gutter-width;
}

.item-equipa {

    margin-top: 10px;
    margin-bottom: 5px;

    .photo {
        display: block;
        width: 100px;
        margin: 0 0 5px 0;
    }
    
}

/** -----------------------------------------------------------------------
 *  HIGHLIGHTS
 *  -----------------------------------------------------------------------
 */

.highlights {

    position: relative;
    height: 205px;
    margin-top: ($grid-gutter-width / 2);
    margin-bottom: ($grid-gutter-width / 2);
    font-family: $font-family-sans-serif;

    &-link {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;

        &::after {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            // background: linear-gradient(135deg, rgba(red(lighten($purple, 40)), green(lighten($purple, 40)), blue(lighten($purple, 40)), 0.2) 0%, rgba(red($purple), green($purple), blue($purple), 0) 50%, rgba(red($purple), green($purple), blue($purple), 0) 100%) repeat scroll 0 0%, 
            //             linear-gradient(45deg, rgba(red($blue), green($blue), blue($blue), 0) 0%, rgba(red($blue), green($blue), blue($blue), 0) 50%, rgba(red($blue), green($blue), blue($blue), 0.2) 100%) repeat scroll 0 0%, 
            //             rgba(0, 0, 0, 0) linear-gradient(transparent 5%, rgba(0, 0, 0, 0.6) 100%) repeat scroll 0 0;
            background: linear-gradient(45deg, rgba(red($blue), green($blue), blue($blue), 0) 0%, rgba(red($blue), green($blue), blue($blue), 0) 50%, rgba(red($blue), green($blue), blue($blue), 0.2) 100%) repeat scroll 0 0%, 
                        rgba(0, 0, 0, 0) linear-gradient(transparent 30%, rgba(0, 0, 0, 0.5) 100%) repeat scroll 0 0;
            content: '';
            z-index: 0;
        }
    }

    &-label {
        position: absolute;
        bottom: 0px;
        margin: 0;
        padding: 15px;
        font-size: 13px;
        line-height: 1.35;
        color: #FFF;
        z-index: 1;

        &::after {
            position: absolute;
            top: 0;
            left: 15px;
            width: 50px;
            height: 1px;
            background-color: #FFF;
            content: '';
        }
    }

    &-nav-button {
        position: absolute;
        top: 78px;
        color: transparentize($purple, .8);

        &.previous {
            left: -($grid-gutter-width / 2);
        }

        &.next {
            right: -($grid-gutter-width / 2);
        }

        &:hover {
            color: $purple;
        }
    }

}


/** -----------------------------------------------------------------------
 *  LIST
 *  -----------------------------------------------------------------------
 */

.content-list {

    &-item {
        margin-top: ($grid-gutter-width / 2);
        margin-bottom: ($grid-gutter-width / 2);
    }

    // &-item + &-item {
    //     margin-top: $grid-gutter-width;
    // }

    &-image {
        border: 1px solid $gray-very-light;
    }

    &-date,
    &-type {
        margin: 0 0 2px 0;
        font-family: $font-family-sans-serif;
        font-size: $font-size-small;
        color: $gray; 
    }

    &-title {
        display: block;
        margin: 0 0 5px 0;
        font-weight: 500;
        text-transform: none;
        color: $purple;

        a {
            &:hover {
                text-decoration: none;
                color: $purple;
            }
        }
    }

    &-details {
        margin: 0;
    }

    &.bordered > li {
        margin-top: 0;
        margin-bottom: 0;
        padding-top: ($grid-gutter-width / 2);
        padding-bottom: ($grid-gutter-width / 2);
        border-bottom: 1px solid $hr-border;

        > a {
            display: block;

            &:hover,
            &:focus {
                text-decoration: none;
            }
        }
    }
}


/** -----------------------------------------------------------------------
 *  FULLSCREEN
 *  -----------------------------------------------------------------------
 */

.fullscreen {
    display: block;
    position: absolute;

    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;

    color: #FFF;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #F5F5F5;
    z-index: 0;

}


/** -----------------------------------------------------------------------
 *  DETALHES DE CONTEUDO
 *  -----------------------------------------------------------------------
 */

.agenda,
.eventos,
.documento,
.projeto,
.campanha {

    .page-subtitle {
        margin-bottom: ($grid-gutter-width / 2);
    }

}

.formacao {

    .page-subtitle {
        margin-bottom: (floor($grid-gutter-width / 4));
    }

}

.centro-de-documentacao .publication-title {
    display: block;
    margin-top: ($grid-gutter-width / 2);
    font-family: $font-family-sans-serif-condensed;
    font-size: $font-size-small;
}

.publication-image {
    float: left;
    margin-right: $grid-gutter-width;
    margin-bottom: ($grid-gutter-width / 2);
    border: 1px solid #CCC;
}

.nucleos #map {
    width: 100%;
    height: 200px;
    margin-top: $grid-gutter-width;
    margin-bottom: $grid-gutter-width;
}


/** -----------------------------------------------------------------------
 *  CONHEÇA MELHOR
 *  -----------------------------------------------------------------------
 */

.conheca-melhor {

    margin-bottom: $grid-gutter-width;

    a {
        position: relative;
        display: block;
        margin-bottom: 5px;
        padding: 10px 5px;
        font-family: $font-family-sans-serif-condensed;
        font-size: $font-size-large;
        font-weight: 700;
        text-transform: uppercase;
        color: $purple;
        @include gradient(transparentize($blue, .8), 135, $purple, .4, 0);

        &:hover,
        &:active {
            color: #FFF;
            text-decoration: none;
            @include gradient($blue, 135, $purple, 1, .15);
            // @include gradient(transparentize($blue, .6), 135, $purple, .6, .1);
        }

        .fa {
            margin-right: 5px;
            line-height: 26px;
        }
    }
}

/** -----------------------------------------------------------------------
 *  ASSOCIADOS
 *  -----------------------------------------------------------------------
 */

.associate-users {
    label {
        font-size: $font-size-small;
        color: $gray-darker;
        margin-left: 15px;
    }

    input {
        display: inline;
        width: 250px;
        margin-left: 10px;
    }

}


/** -----------------------------------------------------------------------
 *  CONTACTS
 *  -----------------------------------------------------------------------
 */

.contactos .main {
    padding-top: 0;
}

.section-google-map {
    height: 300px;
    margin-bottom: $grid-gutter-width;
    @include box-shadow(0px -7px 30px #000000)
}


/** -----------------------------------------------------------------------
 *  FOOTER
 *  -----------------------------------------------------------------------
 */

.footer {
    padding-bottom: ($grid-gutter-width / 2);

    font-family: $font-family-sans-serif;

    -webkit-box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.2);
    -moz-box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.2);
    box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.2);

    // @include gradient($gray-lighter, 160, $purple, 0, .15);

    &, 
    & a {
        color: $gray-darker;
        text-decoration: none;
    }

    &-title {
        font-weight: 700;
    }

    &-bar {
        margin-bottom: ($grid-gutter-width / 2);
        padding-top: 5px;
        padding-bottom: 5px;
        font-family: $font-family-sans-serif;
        background-color: rgba(0, 0, 0, .07);
        
        &, 
        & a {
            color: $purple
        }
    }

    &-content {
        font-size: 14px;
    }

    &-content-title,
    &-link-title {
        display: block;
        font-weight: 500;
        color: $purple;
    }

    &-link {
        white-space: nowrap;
        &::after {
            position: relative;
            top: -1px;
            font-size: $font-size-smaller;
            content: '\00a0\00a0/\00a0\00a0';
        }
    }

    &-link-social {
        margin-right: 15px;
        .fa {
            margin-right: 5px;
        }
    }

    .back-to-top {
        margin-top: ($line-height-computed / 2);
        margin-bottom: ($line-height-computed / 2);
        font-size: $font-size-small;
        text-transform: uppercase;
    }

    hr {
        border-top-color: $gray;
    }

    .bizview {
        display: block;
        position: relative;
        width: 80px;
        height: 20px;
        top: -3px;
        background-image: url('../img/bizview.svg');
        background-position: center center;
        background-repeat: no-repeat;
    }

}

/** -----------------------------------------------------------------------
 *  FORMS
 *  -----------------------------------------------------------------------
 */

.form-control {
    outline: none;

    &:focus {
        box-shadow: 0 0 0;
    }
}

// .btn-submit {
//     margin-top: 15px;
//     border-radius: 0;
//     border: 1px solid $purple;
//     background: transparent;
//     font-family: $font-family-serif;
//     color: $purple;
//     font-size: $font-size-h4;

//     &:hover {
//         color: #fff;
//         @include gradient($blue, 135, $purple, 1, .15);
//     }
// }



/** -----------------------------------------------------------------------
 *  MODALS
 *  -----------------------------------------------------------------------
 */
 .modal-mailchimp {

    .mc-field-group {
        margin-bottom: 10px;
    }

    .response {
        font-family: $font-family-sans-serif;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    div.mce_inline_error {
        margin-top: 5px;
        font-size: $font-size-small;
        color: $brand-primary;
    }

    small {
        display: block;
        a{
            text-decoration: underline;
        }
    }
 }



/** -----------------------------------------------------------------------
 *  HELPER CLASSES
 *  -----------------------------------------------------------------------
 */

.centered-table {
    position: relative;
    display: table;
    height: 100%;
    width: 100%;

    > .content-table {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
    }
}

.row-height {
  display: table;
  table-layout: fixed;
  height: 100%;
  width: 100%;
}

.col-height {
  display: table-cell;
  float: none;
  height: 100%;
}

.block {
    display: block !important;
}

.inline-block {
    display: inline-block !important;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.semi-bold {
    font-weight: 600 !important;
}

.bold {
    font-weight: 700 !important;
}

.font-small {
    font-size: $font-size-small !important;
}

.no-padding {
    padding: 0 !important;
}

.no-padding-left {
    padding-left: 0 !important;
}

.no-padding-right {
    padding-right: 0 !important;
}

.no-margin {
    margin: 0 !important;
}

.p-margin {
    margin: 0 0 ($line-height-computed / 2);
}

.margin-left {
    margin-left: ($grid-gutter-width / 2) !important;
}

.margin-right {
    margin-right: ($grid-gutter-width / 2) !important;
}

.no-glow  {
    outline: none;
    box-shadow:none !important;    
}

.no-border {
    border: none !important;
}

.color-blue {
    color: $blue !important;
}